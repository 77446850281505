<template>
  <div style="height: 100%">
    <v-card height="100%">
      <v-card-title class="pa-0" style="height: 50px;">
        <v-toolbar :color="systemDetails.themecolor" dark  height="50px" class="pb-3">
          <v-toolbar-title :style="`color: ${systemDetails.textcolor}`" class="pr-5">{{ !modelObj.showWorkflows ? $t('addRoute') : $t('listOfWorkflows')}}</v-toolbar-title>
          <v-btn small :color="systemDetails.textcolor" :style="`color: ${systemDetails.themecolor}`" dark @click="enableWorkflowList" v-if="!modelObj.showWorkflows && modelObj.listOfWorkflows && modelObj.listOfWorkflows.length > 0">{{ $t('workflows') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialogHandler">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0" style="height: calc(100% - 50px); overflow-y: auto">
        <v-stepper non-linear v-model="routeStep" v-if="!modelObj.showWorkflows" min-height="100%">
          <v-stepper-header>
            <template v-for="(n) in stepperHeader">
            <v-stepper-step :key="`${n.text}-step`" :step="n.value" :editable="canEdit(n.value)" edit-icon="mdi-check" @click="n.value === 3  ? canEdit(3) && checkForDuplicateValues() : ''">
              {{ n.text }}
            </v-stepper-step>
            <v-divider v-if="n !== stepperHeader" :key="n.id"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-content class="pa-0" step="1">
            <v-card-text class="pa-3" v-if="routeStep === 1">
              <v-form v-model="isBaiscFormValid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field dense hide-details outlined :label="$t('subject')" :rules="$_requiredValidation" v-model="modelObj.subject"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea dense hide-details outlined :label="$t('description')" v-model="modelObj.description"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <span class="subtitle-2">{{ $t('actions') }}:</span>
                    <v-radio-group v-model="modelObj.action" :rules="$_requiredValidation" @change="renameSubject">
                      <v-radio v-for="action in listOfRouteActions" :key="action.id" :label="action.text" :value="action.id"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-form>
              <v-flex class="text-end">
                <v-btn color="primary" small :disabled="!isBaiscFormValid" @click="routeStep = routeStep + 1">{{ $t('continue') }}</v-btn>
              </v-flex>
          </v-card-text>
          </v-stepper-content>
          <v-stepper-content class="pa-0" step="2">
            <v-card-text class="pa-3" v-if="routeStep === 2">
              <v-row>
                <v-col cols="12">
                  <v-simple-table dense width="100%" class="dense_table bordered--table mt-2">
                    <thead>
                      <tr>
                        <th id="">{{ $t('user') }}</th>
                        <th id="">{{ $t('order') }}</th>
                        <th id="">{{ $t('comments') }}</th>
                        <th id=""></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(filter, i) in modelObj.recipients" :key="i">
                        <td style="width:40%">
                          <v-select :items="getUsers" class="mb-2" item-text="name" item-value="_id" dense hide-details v-model="filter.userid" @change="updateDom"
                            :rules="$_requiredValidation"></v-select>
                        </td>
                        <td style="width:15%">
                          <v-text-field dense hide-details @keydown="checkInteger" type="number" v-model="filter.order"></v-text-field>
                        </td>
                        <td style="width:40%">
                          <v-text-field dense hide-details v-model="filter.comments"></v-text-field>
                        </td>
                        <td style="width:5%">
                          <v-btn color="secondary" text fab dark x-small class="mt-1 pb-0" @click="removeRecipientItem(i)"><v-icon dark>mdi-minus-circle</v-icon></v-btn>
                        </td>
                      </tr>
                    </tbody>
                    </v-simple-table>
                    <v-flex class="text-start pt-3">
                      <v-btn color="success" class="mt-2 mb-0 pb-0" fab x-small @click="addNewItem"><v-icon>mdi-plus</v-icon></v-btn>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" class="ml-3 mt-2" dark v-bind="attrs" v-on="on">{{ $t('addGroups') }}</v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-for="(group, index) in getUserGroups" :key="index" @click="adduserFromGroups(group._id)">
                              <v-list-item-title>{{ group.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                      </v-menu>
                    </v-flex>
                </v-col>
                <v-col cols="12" class="text-end">
                  <v-btn color="primary" small :disabled="!(modelObj.recipients && modelObj.recipients.length && modelObj.recipients[0].userid)" @click="checkForDuplicateValues" v-if="routeStep === 2">{{ $t('continue') }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-stepper-content>
          <v-stepper-content class="pa-0" step="3">
            <v-card-text class="pa-3" v-if="routeStep === 3">
              <h3>{{ $t('escalation') }}:</h3>
              <v-switch dense v-model="modelObj.doescalation" inset hide-details :label="$t('ifNotCompleted')" class="mb-2"></v-switch>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-menu v-model="modelObj.dateMenu" :close-on-content-click="false" ransition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field clearable readonly v-on="on" hide-details v-model="modelObj.escalationdeadlinedate" outlined
                        @click:clear="$nextTick(() => modelObj.datePicker = null)" :label="$t('by')" dense :disabled="!modelObj.doescalation"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="modelObj.datePicker" @input="modelObj.escalationdeadlinedate = parseDate(modelObj.datePicker), modelObj.dateMenu = false" color="primary" @change="clearValues('escalationdeadlinedays')"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1" class="pt-5 pa-0">{{ $t('or') }}</v-col>
                <v-col cols="5">
                  <v-text-field dense outlined hide-details type="number" :label="$t('within')" :suffix="$t('days')" v-model="modelObj.escalationdeadlinedays" @keyup="clearValues('escalationdeadlinedate')" :disabled="!modelObj.doescalation"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select :items="listOfEscalationItems" item-text="text" v-model="modelObj.escalationaction" item-value="id" outlined dense hide-details :label="$t('action')" :disabled="!modelObj.doescalation"></v-select>
                </v-col>
              </v-row>
              <v-divider class="pb-3"></v-divider>
              <h3>{{ $t('notification') }}:</h3>
              <v-row class="mt-2">
                <v-col cols="12" class="pb-0 mb-0">
                  <span>{{ $t('notify') }}:</span>
                </v-col>
                <v-col cols="12">
                  <v-simple-table dense width="100%" class="dense_table bordered--table mt-2">
                    <thead>
                      <tr>
                        <th id="">{{ $t('userOrGroup') }}</th>
                        <th id="">
                           <v-btn color="primary" small fab text :title="$t('addNew')" @click="addNotifyItem">
                            <v-icon meduim>mdi-plus-circle</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(notify, i) in modelObj.usersorgroupstonotify" :key="i">
                        <td style="width:40%">
                          <v-select :items="groupsAndUsersArray" class="mb-2" item-text="name" item-value="alternateid" dense hide-details v-model="notify.userid"
                            :rules="$_requiredValidation" :disabled="notify.disabled"></v-select>
                        </td>
                        <td style="width:5%">
                          <v-btn color="secondary" text fab dark x-small class="mt-1 pb-0" @click="removeNotifyUserItem(i)" :disabled="notify.disabled">
                            <v-icon dark>mdi-minus-circle</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    </v-simple-table>
                </v-col>
                <v-col cols="12">
                  <span>{{ $t('notifyOn') }}:</span>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="10" class="pt-0">
                  <template v-for="action in listOfNotifyActions">
                    <v-checkbox v-model="modelObj.notifyonactions" :label="action.name" :value="action.id" :key="action.id" hide-details dense></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" class="pt-0 text-end">
                  <v-btn small color="success" @click="saveHandler" :loading="saveLoader">{{ $t('DONE') }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-stepper-content>
        </v-stepper>
        <template v-else>
          <v-progress-linear value="15" v-if="changeStatusLoader"></v-progress-linear>
          <v-simple-table dense class="bordered--table pa-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th id="" class="text-left" style="width:30%">{{ $t('subject')}}</th>
                  <th id="" class="text-left" style="width:30%">{{ $t('description') }}</th>
                  <th id="" class="text-left" style="width:10%">{{ $t('status') }}</th>
                  <th id="" class="text-left" style="width:10%">{{ $t('action') }}</th>
                  <th id="" class="text-left" style="width:10%">{{ $t('createdOn')}}</th>
                  <th id="" style="width:10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in modelObj.listOfWorkflows" :key="i">
                  <td>{{ item.subject }}</td>
                  <td>{{ item.description }}</td>
                  <td><v-chip :color="getWorkflowStatusName(item.status).color" class="white--text" small>{{ getWorkflowStatusName(item.status).name }}</v-chip></td>
                  <td>{{ getActionName(item.action) }}</td>
                  <td><span class="d-inline-block text-truncate">{{ item.created_at ? $formatter.formatDate(item.created_at, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`)  : '' }}</span></td>
                  <td>
                    <v-row no-gutters>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" dark icon v-bind="attrs" v-on="on" small>
                            <!-- {{ $t('actions') }} -->
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item v-for="(action, index) in getActionItems(item)" @click="action.callback ? action.callback(item, action.action) : ''" :key="index">
                            <v-list-item-title>{{ action.text }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <v-card-actions v-if="modelObj.showWorkflows">
          <v-flex class="pa-3">
            <v-btn color="success" outlined @click="enableAddStepper">{{ $t('addNew') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-dialog width="1200" fullscreen transition="dialog-bottom-transition" v-model="workflowDetailsViewDialog">
      <workflow-detail v-if="workflowDetailsViewDialog" :workflowDetail="workflowDetail" :recordName="recordName"></workflow-detail>
    </v-dialog>
    <v-dialog v-model="cancelConfirmDialog" persistent width="400" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('confirmation') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('cancelConfirmMsg') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="error" id="deleteConfirm" class="mr-3" :loading="changeStatusLoader" @click="confirmCancelHandler">{{ $t('yes') }}</v-btn>
            <v-btn color="primary" id="deleteClose" @click="closeCancelConfirmDialog">{{ $t('no') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['routeObj', 'moduleObj', 'hasDocumentRedirectId', 'recordName'],
  data () {
    return {
      modelObj: {},
      routeStep: 1,
      isBaiscFormValid: false,
      saveLoader: false,
      workflowDetailsViewDialog: false,
      workflowDetail: {},
      workflowAction: null,
      changeStatusLoader: false,
      cancelConfirmDialog: false,
      cancelObj: {},
      groupsAndUsersArray: []
    }
  },
  components: {
    'workflow-detail': () => import('./WorkflowDetails')
  },
  mounted () {
    this.modelObj = this.$formatter.cloneVariable(this.routeObj)
    const self = this
    this.modelObj.listOfWorkflows = this.modelObj.listOfWorkflows.sort((a, b) => {
      return self.$formatter.sortWithDates(b.created_at, a.created_at, 'DD.MM.YYYYTHH:mm:ss')
    })
    this.modelObj.subject = `${this.modelObj.document ? this.modelObj.document.name : ''}`
    this.modelObj.recipients = []
    this.modelObj.notifyonactions = []
    this.$eventBus.$on('closeWorkflowDetail', () => {
      this.workflowDetailsViewDialog = false
    })
    this.groupsAndUsersArray = [...this.$formatter.cloneVariable(this.getUserGroups), ...this.getUsers]
  },
  watch: {
    'modelObj.doescalation' () {
      this.modelObj.escalationdeadlinedate = null
      this.modelObj.datePicker = null
      this.modelObj.escalationdeadlinedays = null
      this.$set(this.modelObj, 'escalationaction', null)
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getUserGroups', 'systemDetails', 'userDetails']),
    stepperHeader () {
      return [{
        text: this.$t('basicInformationAndAction'),
        value: 1
      }, {
        text: this.$t('recipients'),
        value: 2
      }, {
        text: this.$t('escalationAndNotification'),
        value: 3
      }]
    },
    workFlowActions () {
      return [{
        text: this.$t('onHold'),
        action: 2,
        callback: this.changeStatus
      }, {
        text: this.$t('cancelled'),
        action: 3,
        callback: this.changeStatus
      }]
    }
  },
  methods: {
    checkInteger (e) {
      if (['.', '-', '+'].includes(e.key)) e.preventDefault()
    },
    addNewItem () {
      const obj = this.$formatter.cloneVariable(this.modelObj)
      obj.recipients.push({})
      this.modelObj = Object.assign({}, this.modelObj, obj)
    },
    parseDate (date) {
      return this.$formatter.formatDate(date, 'YYYY-MM-DD', this.userDetails.dateformat)
    },
    closeDialogHandler () {
      this.$eventBus.$emit('closeRouteDialog')
    },
    renameSubject () {
      const filter = this.listOfRouteActions.find(x => x.id === this.modelObj.action)
      // if (filter) this.modelObj.subject = this.modelObj.subject ? `${this.modelObj.subject} - ${filter.text}` : filter.text
      if (filter) this.modelObj.subject = this.routeObj.document.name + ' - ' + filter.text
    },
    adduserFromGroups (groupid) {
      const modelObj = this.$formatter.cloneVariable(this.modelObj)
      this.getUsers.forEach(user => {
        if (user.groups && user.groups.length > 0) {
          const result = user.groups.find(x => x === groupid)
          if (result) {
            modelObj.recipients.push({ userid: user._id })
          }
        }
      })
      this.modelObj = Object.assign({}, this.modelObj, modelObj)
    },
    removeRecipientItem (index) {
      this.modelObj.recipients.splice(index, 1)
    },
    clearValues (field) {
      if (field === 'escalationdeadlinedate') {
        this.modelObj.escalationdeadlinedate = null
        this.modelObj.datePicker = null
      } else this.modelObj[field] = null
    },
    saveHandler () {
      const model = this.$formatter.cloneVariable(this.modelObj)
      model.routedocorfolderid = model.document.id
      model.isFolder = model.document.mimetype === 'folder'
      model.moduleobjid = this.moduleObj.recordId
      model.module = this.moduleObj.moduleName
      model.escalationdeadlinedate = model.escalationdeadlinedate ? this.$formatter.formatDate(model.escalationdeadlinedate, this.userDetails.dateformat, 'YYYY-MM-DDTHH:mm:ss') : null
      this.saveLoader = true
      const checkNotifyHasDuplicate = this.checkArrayOfObjectValueHasDuplicate(model.usersorgroupstonotify, 'userid')
      if (checkNotifyHasDuplicate) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'hasDuplicateUser' })
      } else {
        this.saveLoader = true
        model.usersorgroupstonotify.forEach((item) => {
          const userorGroupId = item.userid.split('_')
          if (userorGroupId[0] === 'user') {
            item.userid = userorGroupId[1]
            item.groupid = null
          } else {
            item.groupid = userorGroupId[1]
            item.userid = null
          }
        })
        this.$api.execute('post', 'documentworkflows/create', model)
          .then(() => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'createdSuccess' })
            this.closeDialogHandler()
          })
          .finally(() => {
            this.saveLoader = false
          })
      }
    },
    checkForDuplicateValues () {
      const recipentArray = this.$formatter.cloneVariable(this.modelObj.recipients)
      const hasDuplicateUser = this.checkArrayOfObjectValueHasDuplicate(recipentArray, 'userid')
      var hasDuplicateOrder = this.checkArrayOfObjectValueHasDuplicate(recipentArray, 'order')
      if (hasDuplicateUser || hasDuplicateOrder) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: hasDuplicateUser ? 'hasDuplicateUser' : 'hasDuplicateOrder' })
        setTimeout(() => {
          this.routeStep = 2
        })
      } else {
        this.routeStep = this.routeStep + 1
        this.modelObj.usersorgroupstonotify = [{
          userid: `user_${this.userDetails._id}`,
          disabled: true
        }]
      }
    },
    updateDom () {
      this.$forceUpdate()
    },
    checkArrayOfObjectValueHasDuplicate (arrayOfValues, key) {
      var valueArr = arrayOfValues.map(item => item[key])
      var hasDuplicates = valueArr.some((item, idx) => {
        if (item) {
          return valueArr.indexOf(item) !== idx
        } else return false
      })
      return hasDuplicates
    },
    getActionName (action) {
      const resultFound = this.listOfRouteActions.find(x => x.id === action)
      if (resultFound) return resultFound.text
      else return ''
    },
    enableAddStepper () {
      this.modelObj.showWorkflows = false
    },
    enableWorkflowList () {
      this.modelObj.showWorkflows = true
    },
    openDetailsModel (item) {
      const workflowDetails = this.$formatter.cloneVariable(item)
      workflowDetails.document = this.$formatter.cloneVariable(this.modelObj.document)
      this.workflowDetailsViewDialog = true
      this.workflowDetail = workflowDetails
    },
    getActionItems (item) {
      let actions = [{
        action: 1,
        text: this.$t('view'),
        callback: this.changeStatus
      }]
      if (item.status) {
        if (item.status === this.STATUS_CREATED || item.status === this.STATUS_INPROGRESS) {
          actions = [...actions, ...this.workFlowActions]
        }
        if (item.status === this.STATUS_ONHOLD) {
          const onHoldAction = [{
            action: 4,
            text: this.$t('resume'),
            callback: this.changeStatus
          }]
          actions = [...actions, ...onHoldAction]
        }
      }
      return actions
    },
    changeStatus (workflow, value) {
      switch (value) {
        case 1: this.openDetailsModel(workflow)
          break
        case 2: this.updateWorkflowStatus(this.STATUS_ONHOLD, workflow)
          break
        case 3: this.showCancelConfirmDialog(workflow)
          break
        case 4: this.updateWorkflowStatus(null, workflow)
          break
      }
    },
    showCancelConfirmDialog (workflow) {
      this.cancelConfirmDialog = true
      this.cancelObj = workflow
    },
    closeCancelConfirmDialog () {
      this.cancelConfirmDialog = false
      this.cancelObj = {}
    },
    confirmCancelHandler () {
      this.updateWorkflowStatus(this.STATUS_CANCELLED, this.cancelObj)
      this.confirmCancelHandler = false
    },
    updateWorkflowStatus (status, workflow) {
      this.changeStatusLoader = true
      let url = `documentworkflows/update_workflow_state/${workflow._id}?state=${status}`
      if (!status) url = `documentworkflows/resume_workflow/${workflow._id}`
      this.$api.execute('post', url).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updateSuccess' })
        this.changeStatusLoader = false
      }).finally(() => {
        this.$eventBus.$emit('reloadWorkflowDialog', this.modelObj.document)
      })
    },
    canEdit (value) {
      switch (value) {
        case 1: return true
        case 2: return this.isBaiscFormValid
        case 3: return (this.modelObj.recipients && this.modelObj.recipients.length > 0 && !!this.modelObj.recipients[0].userid)
      }
    },
    removeNotifyUserItem (i) {
      this.modelObj.usersorgroupstonotify.splice(i, 1)
    },
    addNotifyItem () {
      const modelObj = this.$formatter.cloneVariable(this.modelObj)
      modelObj.usersorgroupstonotify = [...modelObj.usersorgroupstonotify, { userid: null }]
      this.modelObj = Object.assign({}, this.modelObj, modelObj)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('closeWorkflowDetail')
  }
}
</script>
